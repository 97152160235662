<template>
  <el-dialog
    title="账号密码错误"
    custom-class="password-dialog"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="closeDialog"
    width="700px"
  >
    <el-form label-position="right" label-width="70px" :model="formData">
      <el-form-item label="邮箱账号">
        <el-input disabled v-model="formData.mailFullAccount"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <input-password v-model="formData.mailPassword"></input-password>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <en-button type="primary" :loading="btnLoading" style="font-size: 12px;" @click="submit" size="medium">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import InputPassword from "./InputPassword";

export default {
  name: "PasswordDialog",
  components: {
    InputPassword
  },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      formData: {
        mailFullAccount: "",
        mailPassword: ""
      }
    };
  },
  methods: {
    submit() {
      if (!this.formData.mailPassword) {
        this.$message.error("请填写密码");
        return;
      }
      this.btnLoading = true;
      this.$emit("submit", this.formData);
    },
    show(accountInfo) {
      this.formData.mailFullAccount = accountInfo.errorMailAccount;
      this.formData.id = accountInfo.errorMailAccountId;
      this.dialogVisible = true;
    },
    hide() {
      this.btnLoading = false;
      this.dialogVisible = false;
    },
    closeDialog() {
      this.formData.mailPassword = "";
      this.$emit("closeDialog");
    }
  }
};
</script>

<style lang="scss">
.password-dialog {
  .el-dialog__footer {
    padding-top: 0px !important;
  }
  .el-dialog__body {
    // 详情页面内 弹框给了 背景色 这里重置一下白色
    background: #ffffff !important;
    padding-top: 10px !important;
  }
}
</style>
